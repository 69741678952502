import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../lib/utils';
// import { Slot } from '@radix-ui/react-slot';

{
  /* <div class="h-14 bg-gradient-to-r from-cyan-500 to-blue-500"></div> */
}

{
  /* <Button
_focus={{ boxShadow: 'none' }}
alignSelf={'flex-end'}
colorScheme="brand"
bgGradient="linear(to-r, brand.blue, brand.purple)"
_hover={{ opacity: 0.9 }}
fontWeight="bold"
leftIcon={<FiSave />}
isLoading={isLoading}
onClick={async () => {
  await onSave();
}}
>
{t.saveDashboard}
</Button> */
}

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors  disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        'adstart-inverted':
          'text-[#1C45D6] bg-clip-text hover:opacity-90 border-2 border-[#1C45D6] font-nunito font-extrabold text-md',
        adstart:
          'text-white bg-gradient-to-r from-[#1C45D6] to-[#7903EF] hover:opacity-90 font-nunito font-extrabold text-md',
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const ShadCnButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    const Comp = 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

ShadCnButton.displayName = 'ShadCnButton';

export { ShadCnButton, buttonVariants };
