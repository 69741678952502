import Lottie from 'react-lottie';
import animationData from './tumbleweed-rolling.json';

export function Tumbleweed({ height = 200 }) {
  return (
    <Lottie
      height={height}
      options={{
        loop: true,
        autoplay: true,
        animationData,
      }}
    />
  );
}
